import React from "react";
import { Link } from "gatsby";
import logo from "../../../static/images/logo.png";

class Header extends React.Component {
  state = {
    isMobileMenuOpen: false,
    stickyHeaderClass: "",
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll.bind(this));
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll.bind(this));
  }

  handleScroll() {
    const windowTop = window.scrollY + 1;
    if (windowTop > 50) {
      this.setState({
        stickyHeaderClass: "menu-fixed animate__animated animate__fadeInDown",
      });
    } else {
      this.setState({ stickyHeaderClass: "" });
    }
  }

  toggleMenu() {
    this.setState({
      isMobileMenuOpen: !this.state.isMobileMenuOpen,
    });
  }

  render() {
    return (
      <header
        className={`main-menu 
        ${this.state.stickyHeaderClass} 
        ${this.state.isMobileMenuOpen ? "menu-opened" : ""}`}
      >
        <div className="container">
          <nav className="navbar">
            <button
              className={`mobile-toggler ${
                this.state.isMobileMenuOpen ? "act" : ""
              }`}
              onClick={() => this.toggleMenu()}
              type="button"
              data-toggle="collapse"
              aria-label="Toggle navigation"
            >
              <div className="menu-icon" />
            </button>

            <Link to="/" className="main-logo">
              <img src={logo} alt="Logo" />
            </Link>

            <div
              className={`menu-container ${
                this.state.isMobileMenuOpen ? "act" : ""
              }`}
            >
              <ul className="navbar-nav">
                <li className="nav-item">
                  <Link
                    to="/o-mnie"
                    className="nav-link"
                    activeClassName="active"
                  >
                    O mnie
                  </Link>
                </li>

                <li
                  className="nav-item dropdown"
                >
                  <Link
                    className="nav-link dropdown-toggle"
                    to="/oferta"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Oferta
                  </Link>
                  <div className="dropdown-menu">
                    <Link
                      onClick={() => this.toggleMenu()}
                      to="/oferta/pelna-ksiegowosc"
                      className="dropdown-item"
                    >
                      Pełna księgowość
                    </Link>
                    <Link
                      onClick={() => this.toggleMenu()}
                      to="/oferta/dzialalnosc-gospodarcza"
                      className="dropdown-item"
                    >
                      Działalność gospodarcza
                    </Link>
                    <Link
                      onClick={() => this.toggleMenu()}
                      to="/oferta/kadry-i-place"
                      className="dropdown-item"
                    >
                      Kadry i płace
                    </Link>
                  </div>
                </li>

                <li className="nav-item">
                  <Link
                    to="/cennik"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Cennik
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    to="/kontakt"
                    className="nav-link"
                    activeClassName="active"
                  >
                    Kontakt
                  </Link>
                </li>
              </ul>
            </div>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
