import React from "react"
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"
function SEO({ description, lang, title, titleTemplate }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
          }
        }
      }
    `
  )
  const metaDescription = description || site.siteMetadata.description
  const metaTitleTemplate = titleTemplate || `%s | ${site.siteMetadata.title}`
  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={metaTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
      ]}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": ${site.siteMetadata.title},
          "url": "mlabecka.pl",
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+48 535 785 902",
            "contactType": "Agent"
          }
        }
      `}
      </script>
    </Helmet>
  )
}
SEO.defaultProps = {
  lang: `pl`,
  meta: [],
  description: ``,
}
export default SEO
