import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Smartphone from "../../../static/images/icons/smartphone.svg"
import Mail from "../../../static/images/icons/mail.svg"
import Facebook from "../../../static/images/icons/facebook.svg"

export default function Footer() {
  const query = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "logo-white.png" }) {
        childImageSharp {
          fluid(quality: 90, maxWidth: 300) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <footer className="footer-part">
      <div className="container">
        <div className="row">
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="single-footer-part company-info-text">
              <Link to="/" className="footer-logo">
                <Img fluid={query.logo.childImageSharp.fluid} alt="Logo" />
              </Link>
            </div>
          </div>
          <div className="col-sm-4 col-md-6 col-lg-4">
            <div className="single-footer-part">
              <h4>Menu</h4>
              <ul className="list-unstyled menu-links">
                <li>
                  <Link to="/o-mnie">
                    O mnie
                  </Link>
                </li>
                <li>
                  <Link to="/oferta">
                    Oferta
                  </Link>
                </li>
                <li>
                  <Link to="/cennik">Cennik</Link>
                </li>
                <li>
                  <Link to="/kontakt">Kontakt</Link>
                </li>
                <li>
                  <Link to="/regulamin">Regulamin</Link>
                </li>
                <li>
                  <Link to="/polityka-prywatnosci">Polityka prywatności</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-4 col-md-6 col-lg-4">
            <div className="single-footer-part">
              <h4>Szybki kontakt</h4>
              <ul className="list-unstyled">
                <li>
                  <div className="footer-contact-icon">
                    <Smartphone />
                  </div>
                  535 785 902
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <Facebook />
                  </div>
                  <a
                    href="https://www.facebook.com/Eubezpieczeniaonlinepl-108275964288322/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Mlabecka.pl
                  </a>
                </li>
                <li>
                  <div className="footer-contact-icon">
                    <Mail />
                  </div>
                  <div>
                    <a href="mailto:biuro.mlabecka@gmail.com">
                    biuro.mlabecka@gmail.com
                    </a>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
